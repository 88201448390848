<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Email Addresses</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeateAgain">
        <h5 v-if="addresses.length === 0" class="text-center">No email addresses listed</h5>

        <!-- Row Loop -->
        <b-row v-for="(item, index) in addresses" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="3" md="3" sm="12">
            <b-form-group label="Address Label" :label-for="`address-label-${index}`">
              <b-form-input :id="`address-label-${index}`" type="text" placeholder="Home Address" v-model="item.label" />
            </b-form-group>
          </b-col>

          <!-- Email Address -->
          <b-col lg="5" md="5" sm="12">
            <b-form-group label="Email" :label-for="`address-email-${index}`">
              <b-form-input
                :id="`address-email-${index}`"
                type="email"
                placeholder="student123@domain.com"
                v-model="item.email"
              />
            </b-form-group>
          </b-col>

          <!-- Type -->
          <b-col lg="2" md="2" sm="12">
            <b-form-group label="Type" :label-for="`address-type-${index}`">
              <v-select
                :id="`address-type-${index}`"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="item.type"
                :options="[
                  { label: 'Personal', value: 'personal' },
                  { label: 'School', value: 'school' },
                  { label: 'Work', value: 'work' },
                ]"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (item.type = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="2" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <b-form-checkbox
            v-model="submit.sync"
            class="float-right mr-5"
            style="margin-top: 7px"
            input-id="submit-sync"
            disabled
          >
            Sync
          </b-form-checkbox>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudentsList from '../../students-list/useStudentsList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/views/apps/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },

      addresses: [],
      nextTodoId: 1,
    };
  },
  methods: {
    doSaveChanges() {
      const pwd = this.studentData;
      const id = pwd.id;

      this.addresses = this.addresses.filter((add) => {
        if (!add.email) return false;

        return true;
      });

      store
        .dispatch('app-student/updateStudentProfile', {
          id,
          profileData: { emailAddresses: this.addresses },
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeateAgain() {
      this.addresses.push({ address: {} });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.addresses.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    loadProfile() {
      store.dispatch('app-student/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.addresses = response.data.emailAddresses;
        this.trAddHeight(this.$refs.row[0].offsetHeight * this.addresses.length);

        console.log(`Addresses registered`, this.addresses);
        this.initTrHeight();
      });
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
      }
    });

    const { statusOptions } = useStudentsList();

    return {
      studentDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
